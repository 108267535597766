ul.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

ul.menu li a {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 20px;
  gap: 10px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

ul.menu li a:hover {
  background: linear-gradient(
    180deg,
    rgba(247, 196, 9, 1) 0%,
    rgba(180, 76, 6, 1) 100%
  );
  color: #fff;
  border-radius: 10px;
}
.menu li a.active_menu {
  background: linear-gradient(
    180deg,
    rgba(247, 196, 9, 1) 0%,
    rgba(180, 76, 6, 1) 100%
  );
  color: #fff;
  border-radius: 10px;
}
