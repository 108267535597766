.login-bg {
  background: url("../../images/bg.png") no-repeat center top;
  background-size: cover;
  min-height: 100vh;
  padding: 40px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 40px !important;
  background: #f0f0f0;
  font-size: 16px;
  color: #222943;
}
@media only screen and (max-width: 992px) {
  .login-bg{
    padding: 16px 0px;
    justify-content: flex-start;
  }
}