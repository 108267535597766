@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200..800&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
* {
  box-sizing: border-box;
}

.required {
  color: orangered;
  font-size: 13px;
}
.emojis div {
  font-size: 21px !important;
}

.centered-div {
  height: 300px;
  width: 100%;
  font-size: 30px;
  display: flex;
  justify-content: center; /* centers the text horizontally */
  align-items: center; /* centers the text vertically */
  text-align: center; /* aligns the text within the div */
  padding: 20px; /* add your desired padding */
  margin: 10px; /* add your desired margin */
}
.centered {
  height: 500px;
  width: 100%;
  font-size: 30px;
  display: flex;
  justify-content: center; /* centers the text horizontally */
  align-items: center; /* centers the text vertically */
  text-align: center; /* aligns the text within the div */
  padding: 20px; /* add your desired padding */
  margin: 10px; /* add your desired margin */
}

.slick-dots.custom-dots li button:before {
  color: blue;
}

.slick-dots.custom-dots li.slick-active button:before {
  color: red; /* Change to any color you prefer for the active dot */
}

.form-group {
  margin-bottom: 1rem;
}

.input-field {
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid #687588;
  border-radius: 5px;
}

.country-code,
.phone-number {
  display: inline-block;
  width: calc(50% - 0.5rem);
}

.country-code {
  margin-right: 1rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
button:focus {
  outline: 0;
}
