.datepikerL {
    background: #282520;
    border: 1px solid #fff;
    color: #fff;
    font-size: 15px;
    width: 100%;
    height: 56px;
    padding: 16.5px 14px;
    outline: none;
}

.react-datepicker__calendar-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;
    fill: #fff;
    right: 0px;
    margin-top: 12px;
    cursor: pointer;
}
.react-datepicker__view-calendar-icon input{
    padding: 6px 10px 5px 15px;
}