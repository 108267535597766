body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0px !important;
  overflow: inherit !important;
}

code,
textarea {
  font-family: "Manrope", sans-serif;
}
* {
  box-sizing: border-box;
}
input:-internal-autofill-selected {
  background-color: transparent;
}

.disable-button {
  pointer-events: none;
  opacity: 0.5;
}
body.activeClass .Toastify__toast-body {
  font-family: "Cairo", sans-serif !important;
}
body.activeClass {
  font-family: "Cairo", sans-serif !important;
}
body.activeClass h1 {
  font-family: "Cairo", sans-serif !important;
  text-align: right;
}
body.activeClass h2 {
  font-family: "Cairo", sans-serif !important;
  text-align: right;
}
body.activeClass h3 {
  font-family: "Cairo", sans-serif !important;
  text-align: right;
}
body.activeClass h4 {
  font-family: "Cairo", sans-serif !important;
  text-align: right;
}
body.activeClass h5 {
  font-family: "Cairo", sans-serif !important;
  text-align: right;
}
body.activeClass h6 {
  font-family: "Cairo", sans-serif !important;
  text-align: right;
}
body.activeClass label {
  font-family: "Cairo", sans-serif !important;
  text-align: right;
  display: block;
}
body.activeClass select {
  font-family: "Cairo", sans-serif !important;
}
body.activeClass input {
  font-family: "Cairo", sans-serif !important;
}
body.activeClass span {
  font-family: "Cairo", sans-serif !important;
  text-align: right;
}
body.activeClass p {
  font-family: "Cairo", sans-serif !important;
  text-align: right;
}

body.activeClass button {
  font-family: "Cairo", sans-serif !important;

}
body.activeClass .MuiSelect-select {
  font-family: "Cairo", sans-serif !important;

}
body.activeClass input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: "Cairo", sans-serif !important;
}
body.activeClass input::-moz-placeholder { /* Firefox 19+ */
  font-family: "Cairo", sans-serif !important;
}
body.activeClass input:-ms-input-placeholder { /* IE 10+ */
  font-family: "Cairo", sans-serif !important;
}
body.activeClass input:-moz-placeholder { /* Firefox 18- */
  font-family: "Cairo", sans-serif !important;
}
